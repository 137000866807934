import React, { useMemo, useEffect, useState, useRef } from "react";
import styles from "./index.module.less";
import { Affix, Button, Skeleton, Avatar, message, Breadcrumb } from "antd";
import { HomeArticleModel, InsMonitorModel, HomeGPTsModel } from "@/types/home";
import { getPathTitle, imgUrl } from "@/shared/utils";
import useScreenSize from "@/lib/hooks/utils/useScreenSize";
import { useRouter } from "next/router";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { CommonBaseContent } from "@/components/Layouts/CommonBackContent";
import Link from "next/link";
import dynamic from "next/dynamic";
import { useConfigProvider } from "@/context/ConfigProvider";
import { AppSettings } from "@/shared/app-common";
import { fetchInsMonitorListDemo } from "@/lib/service/home";
import YouTube from "react-youtube";

const BookItem = dynamic(() => import("../BookItem"));
const SharePage = dynamic(() => import("@/components/Common/Share"));
const HomeFullItem = dynamic(() => import("@/components/Home/HomeFullItem"));

const LoadingSkeleton = () => (
  <>
    <Skeleton active />
    <Skeleton.Image
      active
      style={{ marginTop: "20px", width: "100%", aspectRatio: "16/9" }}
    />
    <Skeleton active style={{ marginTop: "20px" }} />
  </>
);

const ArticleDetailUI = ({
  model,
  // gptsList,
  articleList,
  contentData,
  recommendTags,
}: {
  model?: HomeArticleModel;
  gptsList?: [HomeGPTsModel];
  articleList?: [HomeArticleModel];
  contentData?: any;
  recommendTags?: [HomeArticleModel];
}) => {
  const [demoAccountData, setDemoAccountData] = useState<InsMonitorModel[]>([]);
  const leftRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const router = useRouter();
  const { width } = useScreenSize();
  const currentHref = useMemo(
    () => (typeof window !== "undefined" ? window.location.href : ""),
    []
  );
  const { onShowArticleBottomAlert, isMobile } = useConfigProvider();

  const [leftWidth, setLeftWidth] = useState(500);

  useEffect(() => {
    const handleResize = () => {
      if (leftRef.current && leftRef.current.clientWidth > 0) {
        setLeftWidth(leftRef.current.clientWidth);
      }
    };

    setTimeout(() => {
      if (typeof window !== undefined) {
        handleResize();
      }
    }, 2000);

    window.addEventListener("resize", handleResize);

    // 清理事件监听器
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    onShowArticleBottomAlert(isMobile);

    return () => {
      onShowArticleBottomAlert(false);
    };
  }, []);

  const onLinkDemoAccount = (accoutn: InsMonitorModel) => {
    if (!accoutn) return;
    router.push(
      `${AppSettings.webHost}/analytics/${accoutn?.identification}?name=${accoutn?.media_name}&identification=${accoutn?.identification}&avatar=${accoutn?.avatar}`
    );
  };

  const demoAccountList = useMemo(() => {
    return demoAccountData.map((account) => {
      return (
        <div className={styles.accountItem} key={account.identification}>
          <Avatar className={styles.avatar} src={account.avatar} />
          <div className={styles.rightContents}>
            <div className={styles.accountName}>{account.media_name}</div>
            <div
              className={styles.linkButton}
              onClick={() => onLinkDemoAccount(account)}
            >
              {t("See Analytics")}
              {" >"}
            </div>
          </div>
        </div>
      );
    });
  }, [demoAccountData]);

  return (
    <>
      <CommonBaseContent style={{ maxWidth: "1040px" }}>
        {width > 900 && (
          <div style={{ float: "left", marginLeft: "-67px" }}>
            <Affix offsetTop={74}>
              <SharePage shareUrl={currentHref} />
            </Affix>
          </div>
        )}

        <div className={styles.homeContent}>
          <div className={styles.leftContent}>
            <div className={styles.leftContainer}>
              <Breadcrumb
                className={styles.bread}
                items={[
                  {
                    title: (
                      <Link style={{ color: "#B8B8B8" }} href={`/`}>
                        {t("home")}
                      </Link>
                    ),
                  },
                  {
                    title: (
                      <Link style={{ color: "#B8B8B8" }} href={`/blog`}>
                        {t("blog")}
                      </Link>
                    ),
                  },
                  {
                    title: (
                      <Link
                        style={{ color: "#B8B8B8" }}
                        href={`/blog?classifies=${model?.type}`}
                      >
                        {t(`${model?.type}`)}
                      </Link>
                    ),
                  },
                  {
                    title: (
                      <span style={{ color: "var(--app-primary-color)" }}>
                        {t("Article Details")}
                      </span>
                    ),
                  },
                ]}
                separator=">"
                style={{ marginBottom: "15px" }}
              />
              {contentData == undefined || contentData?.title == undefined ? (
                <LoadingSkeleton />
              ) : (
                <>
                  {/* <div className={styles.time}>{model?.create_time}</div> */}
                  {contentData?.book && <BookItem contentData={contentData} />}

                  <h1 className={styles.title}>{contentData?.title}</h1>

                  {contentData?.image_url && (
                    <Image
                      className={styles.image}
                      src={imgUrl(
                        contentData?.image_url,
                        width > 576 ? "big" : "mid"
                      )}
                      loading="lazy"
                      alt={contentData?.image_text}
                      layout="responsive"
                      width={500}
                      height={500}
                    />
                  )}

                  {contentData?.introduction && (
                    <p
                      className={styles.desc}
                      dangerouslySetInnerHTML={{
                        __html: contentData?.introduction,
                      }}
                    />
                  )}
                  {contentData?.body && (
                    <>
                      <div
                        className={styles.content}
                        dangerouslySetInnerHTML={{ __html: contentData?.body }}
                      />
                      {`${contentData?.body}`?.includes(
                        `<dolphin-radar-video/>`
                      ) && (
                        <div
                          ref={leftRef}
                          style={{
                            marginBottom: "20px",
                            width: "100%",
                            aspectRatio: "16/9",
                          }}
                        >
                          <YouTube
                            videoId="iGMQ2qTI-U8"
                            className={styles.youtube}
                            opts={{
                              width: `${Number(leftWidth)}`,
                              height: `${Number((leftWidth * 9) / 16)}`,
                              playerVars: {
                                autoplay: 1,
                              },
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {recommendTags?.length > 0 && (
                    <div className={styles.recommendTags}>
                      <h2>{t("Read More")}</h2>
                      {(recommendTags ?? []).map(
                        (item: HomeArticleModel, index: number) => {
                          return (
                            <div
                              className={styles.linkContainer}
                              key={item?.article_id}
                            >
                              <span>{index + 1}.</span>
                              <Link
                                className={styles.link}
                                href={`/blog/${item?.article_id}/${getPathTitle(
                                  item?.title
                                )}`}
                              >
                                {item.title}
                              </Link>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                  {/* 
              {model?.labels?.length > 0 && (
                <div className={styles.tagContiner}>
                  <span className={styles.tagTitle}>{t('tags')}:</span>
                  {(model?.labels ?? []).map((item: any, index: number) => {
                    const itemStr = index == model?.labels.length - 1 ? item : `${item},`;
                    return (
                      <Link
                        href={`/search?tag=${item}`}
                        key={index}
                        className={styles.tagContent}
                      >
                        {itemStr}
                      </Link>
                    );
                  })}
                </div>
              )} */}

                  {width <= 900 && <SharePage shareUrl={currentHref} />}
                </>
              )}
            </div>
            <div className={styles.otherContainer}>
              {articleList != undefined && articleList?.length > 1 && (
                <>
                  <h3>{t("peopleAlsoEnjoyed")}</h3>
                  {(articleList ?? []).map((item, index) => {
                    return (
                      <HomeFullItem
                        key={index}
                        showBottomLine={index != articleList.length - 1}
                        articleModel={item}
                        showPadding={false}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <div className={styles.rightContent}>
            <div className={styles.nav}>
              <div className={styles.title}>
                Copycobra ：start finding & removing infringements
              </div>
              <Button
                className={styles.button}
                onClick={() => router.push(`${AppSettings.webHost}`)}
              >
                Get Protected Now
              </Button>
            </div>
            {/* <div className={styles.sampleAccountContent}>
              <div className={styles.title}>
                {t("Track Any Instagram Account")}
              </div>
              <div className={styles.desc}>
                {t(
                  "Select an Instagram Activity Sample Report below to see what you'll get"
                )}
              </div>
              <div className={styles.accountList}>{demoAccountList}</div>
            </div> */}
          </div>
        </div>
      </CommonBaseContent>
    </>
  );
};

export default ArticleDetailUI;
